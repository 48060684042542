import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase.config";

export const getAllArtworks = async () => {
    const querySnapshot = await getDocs(collection(db, "artworks"));
    const artworks = querySnapshot.docs.map((doc) => ({
        ...doc.data()
    }));

    return artworks;
};
