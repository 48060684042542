import React, { useContext, useEffect, useState } from "react";
import { storage } from "../config/firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
import { LoadingContext } from "../contexts/LoadingContext";

const ArtworkCard = ({ artworkDetails, setOpenModal, setSelectedArtwork }) => {
    const [imageSrc, setImageSrc] = useState("");
    const imageRef = ref(storage, artworkDetails.imgSrc + ".webp");

    const handleOpenModal = (ev) => {
        setOpenModal(true);
		setSelectedArtwork(artworkDetails);
    };

	const { isLoading } = useContext(LoadingContext);

    useEffect(() => {
        getDownloadURL(imageRef).then((url) => {
            setImageSrc(url);
        });
    });

    return (
        <div className="w-full flex flex-col gap-4 mx-auto">
			{!isLoading ? 
            <div className="w-fit grid gap-4">
                <div className="max-w-full aspect-[15.9_/_14.1]">
                    <img
                        src={imageSrc}
                        alt="artwork"
                        className="max-w-full aspect-[15.9_/_14.1] bg-no-repeat object-cover"
                    />
                </div>
                <div className="flex justify-between items-center font-Libre tracking-[0.1rem]">
                    <span className="text-sm uppercase">
                        {artworkDetails.name}
                    </span>
                    <button
                        className="bg-dark text-sm text-light px-4 py-2"
                        onClick={handleOpenModal}
                    >
                        Artify Your Space
                    </button>
                </div>
            </div>
			:
            <div className="w-full grid gap-4 animate-pulse">
                <div className="max-w-full aspect-[15.9_/_14.1] bg-slate-300 flex justify-center items-center">
                    <svg
                        className="w-10 h-10 text-gray-200 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18"
                    >
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>
				<div className="flex justify-between items-center gap-4">
					<div className="w-full flex flex-col">
						<div className="h-4 bg-gray-300 mb-4 rounded-md"></div>
						<div className="h-3 w-3/5 bg-gray-300 rounded-md"></div>
					</div>
					<div className="h-14 w-full bg-slate-300 rounded-md"></div>
				</div>
            </div>
			}
        </div>
    );
};

export default ArtworkCard;
