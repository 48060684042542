import React from "react";

const PageNotFound = () => {
	return (
		<div className="w-full h-full col-span-full grid items-center text-center">
			<h1>Page Not Found</h1>
		</div>
	);
};

export default PageNotFound;
