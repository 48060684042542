import React from "react";
import { NavLink } from "react-router-dom";

const Menu = () => {

    const menuLinks = [
        {
            to: "/",
            text: "Home",
        },
        {
            to: "/who-am-i",
            text: "Who Am I",
        },
        {
            to: "/shop",
            text: "Shop",
        },
    ];

    return (
        <nav className="fixed left-2/4 -translate-x-2/4 bottom-4 w-fit z-40">
            <ul className="w-auto text-nowrap flex gap-4 p-4 backdrop-blur-xl rounded-full border-[1px] border-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)] font-Poppins text-dark">
                {menuLinks.map((link, i) => {
                    return (
                        <li key={i}>
                            <NavLink
                                to={link.to}
                                className={
									({ isActive }) => isActive ? 'relative before:w-full before:h-[2px] before:bg-dark before:absolute before:-top-[18px] before:shadow-[0_2px_30px_5px_rgba(0,0,0,1)]' : ''
                                }
                            >
                                {link.text}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Menu;
