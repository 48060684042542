import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase.config";
import { toast } from "react-toastify";

export const checkCustomer = async (email) => {
    if (!email) {
        toast.error(`Email field is required!`);
        return;
    }

    try {
        // Query Firestore
        const customerRef = collection(db, "customers"); // Replace 'users' with your collection name
        const q = query(customerRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return false;
        }

        return true
    } catch (error) {
        console.error("Error searching for customer:", error);
        return error;
    }
};

export const createNewCustomer = async (email) => {
    if (!email) {
        toast.error(`Email field is required!`);
        return;
    }
    
    try {

        await addDoc(collection(db, "customers"), {
            email: email,
            discountCode: "@MakeItYours",
            discountCodeUsed: false,
            discountCodeObtainedDate: new Date(),
        });

		return {success: true};
	} catch (error) {
		console.error("Error searching for customer:", error);
		return { error: "Internal Server Error", details: error.message, status: 500 };
	}
}