import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Modal = ({ children, title, setOpenModal }) => {
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <div
                className="fixed top-0 left-0 w-full h-full backdrop-blur-2xl z-50"
                onClick={handleCloseModal}
            ></div>
            <div className="fixed overflow-x-hidden top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 z-50 flex flex-col gap-2 border-black border-[1px] rounded-md w-[90%] lg:w-[812px] h-auto max-h-[90%] overflow-y-auto bg-light shadow-lg">
                {title && (
                    <div
                        className={[
                            "flex",
                            title ? "justify-between" : "justify-end",
                            "gap-8",
                        ].join(" ")}
                    >
                        <h1 className="text-2xl font-bold">{title}</h1>
                    </div>
                )}
                <div className="relative w-full h-full">{children}</div>
            </div>
            <div onClick={handleCloseModal} className="fixed top-0 right-0 px-4 py-2 z-[100] bg-red-500 text-white cursor-pointer">
                <FontAwesomeIcon icon={faXmark}/>
            </div>
        </>
    );
};

export default Modal;
