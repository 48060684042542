import React from "react";
import { Helmet } from "react-helmet";

const WhoAmI = () => {

    return (
        <>
            <Helmet>
                <title>Arthyl - Who Am I</title>
				<meta name="description" content="Arthyl, Number One Plexiglass Artists In The Middle East." />
				<meta name="keywords" content="Arthyl,Art,Plexiglass,Winged Horse,Plexiglass Art,Methyl"/>
            </Helmet>
            <div className="container my-28 pb-28 mx-auto">
                <div className="mx-8 flex flex-col gap-8">
                    <div className="font-Libre text-5xl flex gap-4 items-center text-nowrap">
                        <h1>Who Am I</h1>
                        <hr className="w-full bg-divider" />
                    </div>
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                        <div className="grid gap-4 md:w-4/5 mx-auto">
                            <div className="h-fit mt-12">
                                <p className="font-Poppins text-lg indent-8 text-justify">
                                    I am not your typical artist. I didn’t grow
                                    up surrounded by art, nor did I inherit a
                                    creative legacy. My journey was
                                    different—shaped by an entrepreneurial
                                    mindset and a deep passion for business.
                                    I’ve always been fascinated by the concepts
                                    of growth, perseverance, and the challenges
                                    that shape success. This passion eventually
                                    led me to discover art, not as a traditional
                                    practice but as a medium to express the core
                                    values of ambition and achievement.
                                </p>
                                <p className="font-Poppins text-lg indent-8 text-justify">
                                    For me, art is more than colors or
                                    forms—it’s a visual language of resilience,
                                    triumph, and innovation. Every piece I
                                    create is a reflection of the
                                    entrepreneurial journey, designed to inspire
                                    business owners, dreamers, and leaders who
                                    strive to overcome obstacles and build their
                                    visions. My work isn’t just about creating
                                    beautiful pieces; it’s about crafting
                                    stories that connect deeply with those who
                                    see success as a blend of hard work,
                                    creativity, and endless determination.
                                </p>
                                <p className="font-Poppins text-lg indent-8 text-justify">
                                    Through my art, I aim to create a connection
                                    between the worlds of creativity and
                                    business, offering a unique perspective that
                                    celebrates ambition and the beauty of
                                    challenges. My work is a signature of
                                    growth, designed to motivate and inspire
                                    those who see themselves not just as
                                    spectators, but as achievers
                                </p>
                            </div>
                        </div>
                        <div className="relative flex justify-center row-start-1 lg:row-start-1 lg:col-start-2">
                            <img
                                src="/assets/images/Arthyl-Ahmad.jpg"
                                alt="Arthyl the artist"
                                width="auto"
                                height="auto"
                                className="w-full md:w-4/6 lg:w-full 2xl:w-4/6 object-cover object-bottom "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WhoAmI;
