import React, { useState, useEffect, useContext } from "react";
import ArtworkCard from "../components/ArtworkCard";
import { Helmet } from "react-helmet";
import Modal from "../components/Modal";
import { getAllArtworks } from "../services/artworks.services";
import { LoadingContext } from "../contexts/LoadingContext";
import Inquery from "../components/Inquery";

const Shop = () => {
    const [openModal, setOpenModal] = useState(false);
    const [artworks, setArtworks] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const { setIsLoading } = useContext(LoadingContext);

    const fetchArtworks = async () => {
        try {
            const arts = await getAllArtworks().then((data) =>{
                return data
            })
            setArtworks(arts);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        } catch (error) {}
    };

    useEffect(() => {
        fetchArtworks();
    }, []);

    return (
        <>
            <Helmet>
                <title>Arthyl - Shop</title>
				<meta name="description" content="Arthyl, Number One Plexiglass Artists In The Middle East." />
				<meta name="keywords" content="Arthyl,Art,Plexiglass,Winged Horse,Plexiglass Art,Methyl,Obsidian"/>
            </Helmet>
            <div className="col-span-full container my-28 mx-auto">
                <div className="mx-8 flex flex-col gap-8">
                    <div className="font-Libre text-5xl flex gap-4 items-center">
                        <h1>Shop</h1>
                        <hr className="w-full bg-divider" />
                    </div>
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                        {artworks.map((artwork, index) => {
                            return (
                                <ArtworkCard
                                    key={index}
                                    artworkDetails={artwork}
                                    setOpenModal={setOpenModal}
                                    setSelectedArtwork={setSelectedArtwork}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            {openModal && (
                <Modal
                    setOpenModal={setOpenModal}
                >
                    <Inquery artwork={selectedArtwork} setOpenModal={setOpenModal}/>
                </Modal>
            )}
        </>
    );
};

export default Shop;
