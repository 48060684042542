import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { storage } from "../config/firebase.config";
import { useFormik } from "formik";
import { VALID_EMAIL_REGEX } from "../utils/Form.utils";
import emailjs from "@emailjs/browser";
import {
    checkCustomer,
    createNewCustomer,
} from "../services/customers.services";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const Inquery = ({ artwork, setOpenModal }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imageRef = ref(storage, artwork.templateImgSrc + ".webp");
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = "Email field is required!";
            }

            if (!VALID_EMAIL_REGEX.test(data.email)) {
                errors.email = "Email is not valid!";
            }

            return errors;
        },
        onSubmit: async (data) => {

            // if (!recaptchaToken) {
            //     toast.error("Error sending email!");
            //     return;
            // }

            let hasDiscount = false;

            const customer = await checkCustomer(data.email);
            if (!customer) {
                hasDiscount = true;
                await createNewCustomer(data.email);
            }

            const emailParams = {
                art_name: artwork.name,
                image_src: imageSrc,
                hasDiscount: hasDiscount,
                send_to: data.email,
                from_email: "info@arthyl.com",
            };

            emailjs
                .send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    emailParams,
                    {
                        publicKey: process.env.REACT_APP_EMAILJS_USER_ID,

                    }
                )
                .then(
                    (res) => {
                        toast(
                            `An Inquery has been sent to you, please check your email ${data.email}.`
                        );
                        formik.resetForm();
                        setOpenModal(false);
                    },
                    (error) => {
                        toast.error(
                            `An error happened while sending email for ${data.email}.`
                        );
                    }
                );
        },
    });

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token); // Update the token
    };

    const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="text-red-500">{formik.errors[name]}</small>
        ) : null;
    };

    useEffect(() => {
        getDownloadURL(imageRef).then((url) => {
            setImageSrc(url);
        });
    });

    return (
        <div className="w-full h-full flex flex-col-reverse gap-8 overflow-y-auto md:flex-row">
            <div className="w-full flex flex-col">
                <div className="w-full p-4">
                    <h1 className="font-LibreBold text-3xl pl-4">
                        {artwork.name}
                    </h1>
                    <div className="w-full h-full p-4 flex flex-col gap-4">
                        <ul className="m-0">
                            {artwork.description.map((desc, index) => (
                                <li key={index} className="mb-2">
                                    <p>{desc}</p>
                                </li>
                            ))}
                        </ul>
                        <div className="w-full">
                            <h1 className="mb-2">Available in:</h1>
                            <div className="grid grid-rows-2 gap-4 min-[450px]:grid-cols-2 min-[450px]:grid-rows-1">
                                <div>
                                    <h1 className="font-Poppins text-base">
                                        Large Canvas
                                    </h1>
                                    <p className="font-bold">
                                        W{artwork.dimensions.large.width}cm x H
                                        {artwork.dimensions.large.height}
                                        cm x D{artwork.dimensions.large.depth}cm
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-fit px-8 pb-8 w-full">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="max-w-96 w-full flex flex-col gap-4 mx-auto"
                    >
                        <div className="flex flex-col gap-2">
                            <label htmlFor="email">Email:</label>
                            <input
                                id="email"
                                name="email"
                                className="w-full border-2 border-black rounded-md text-base px-2 py-1 font-Poppins"
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "email",
                                        e.target.value
                                    );
                                }}
                            />
                            {getFormErrorMessage("email")}
                        </div>
                        {/* <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            size="invisible"
                            onChange={handleRecaptchaChange}
                        /> */}
                        <button
                            type="submit"
                            disabled={!formik.isValid || formik.isSubmitting}
                            className={
                                !formik.isValid || formik.isSubmitting
                                    ? "w-full self-end bg-dark text-sm text-light px-4 py-2 opacity-50 cursor-not-allowed"
                                    : "w-full self-end bg-dark text-sm text-light px-4 py-2"
                            }
                        >
                            Request Inquery
                        </button>
                    </form>
                </div>
            </div>
            <div className="w-full h-full">
                {imageSrc ? (
                    <div className="w-full aspect-square">
                        <img
                            src={imageSrc}
                            alt="artwork"
                            className="w-full bg-no-repeat object-cover aspect-square"
                        />
                    </div>
                ) : (
                    <div className="w-full aspect-square md:aspect-square bg-slate-300 flex justify-center items-center">
                        <svg
                            className="w-10 h-10 text-gray-200 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 18"
                        >
                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Inquery;
