import { Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Shop from "./pages/Shop";
import Login from "./pages/Login";
import WhoAmI from "./pages/WhoAmI";

function App() {

    return (
        <>
            {/* {isLoading && <Loader />}
            {!isLoading && ( */}
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<Home />}/>
                        <Route path="/shop" element={<Shop />} />
                        <Route path="/who-am-i" element={<WhoAmI />} />
                        <Route path="/*" element={<PageNotFound />} />
                    </Route>
                    <Route path="/Sign-In" element={<Login />} />
                </Routes>
            {/* )} */}
        </>
    );
}

export default App;
