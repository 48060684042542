import { useFormik } from "formik";
import React from "react";

const Login = () => {

	const formik = useFormik({
		initialValues: {
			email: "",
			password: ""
		},
		validate: (data) => {
			let errors = {};

			if (!data.email) {
				errors.email = "Email is required";
			}

			if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) {
				errors.email = "Email is not valid";
			}

			if (!data.password) {
				errors.password = "Password is required";
			}

			return errors;
		}, 
		onSubmit: (data) => {
			formik.resetForm();
		}
	})

	const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="text-red-500">{formik.errors[name]}</small>
        ) : null
    };

	return (
		<div className="grid w-full h-full justify-center items-center font-Poppins">
			<div className="w-96 grid gap-4 p-8 shadow-2xl border-[1px] rounded-md">
				<h1 className="uppercase font-Libre text-center text-2xl tracking-[0.5rem] md:text-4xl">Arthyl</h1>
				<h1 className="text-center">Sign In</h1>
				<form onSubmit={formik.handleSubmit} className="w-full grid gap-4">
					<div>
						<input
							type="text"
							id="email"
							name="email"
							value={formik.values.email}
							onChange={(e) =>
								formik.setFieldValue(
									"email",
									e.target.value
								)
							}
							className="w-full h-10 p-2 rounded-md border-[1px] border-[#e4e4e7] disabled:cursor-not-allowed"
							placeholder="Enter your email"
						/>
						{getFormErrorMessage("email")}
					</div>
					<div>
						<input
							type="password"
							id="password"
							name="password"
							value={formik.values.password}
							onChange={(e) =>
								formik.setFieldValue(
									"password",
									e.target.value
								)
							}
							className="w-full h-10 p-2 rounded-md border-[1px] border-[#e4e4e7] disabled:cursor-not-allowed"
							placeholder="Enter your password"
						/>
						{getFormErrorMessage("password")}
					</div>
					<button type="submit" className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-dark text-light shadow hover:bg-[#2f2f31] h-9 px-4 py-2">Sign In</button>
				</form>
			</div>
		</div>
	);
};

export default Login;
