import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Menu from "./Menu";
import { Bounce, ToastContainer } from "react-toastify";

const Layout = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <div className="w-full h-full bg-light">
                <div className="absolute left-2/4 -translate-x-2/4 top-4">
                    <img
                        src="/assets/images/Arthyl-Signature-NoBG.png"
                        width={270}
                        height={45}
                        alt="Arthyl Signiture"
                    />
                </div>
                <Menu />
                <main className="w-full h-full">{<Outlet />}</main>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                className="z-[100]"
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
        </>
    );
};

export default Layout;
