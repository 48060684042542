import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";

const Home = () => {

	const galleryImage = useRef(null);
	const obsidianRef = useRef(null);
	const isInView = useInView(obsidianRef, { margin: "0px 0px -70% 0px" });

    return (
        <>
            <Helmet>
                <title>Arthyl - Home</title>
				<meta name="description" content="Arthyl, Number One Plexiglass Artists In The Middle East." />
				<meta name="keywords" content="Arthyl,Art,Plexiglass,Winged Horse,Plexiglass Art,Methyl"/>
            </Helmet>
            <section className="w-full h-full">
                <div
                    ref={galleryImage}
                    className="w-full h-full bg-[url('/public/assets/images/black-winged-horse-gallery-obsidian.jpg')] bg-cover bg-no-repeat bg-center shadow-[0px_10px_100px_#656a6d]"
                ></div>
            </section>
            <section className="relative w-full">
                <div className="relative container mx-auto px-4 py-28">
                    <h1 className="w-3/4 font-Libre text-5xl mb-14 lg:text-8xl uppercase">
                        Why Winged Horse ?
                    </h1>
                    <blockquote className="relative w-full leading-loose md:text-xl pt-16 pb-4 px-4 text-justify xl:w-2/4 ml-auto font-Poppins after:content-['“'] after:absolute after:top-0 after:left-0 after:w-14 after:h-[200px] after:text-[300px] after:leading-[200px] after:flex after:justify-center after:items-center after:font-[sans-serif] tracking-widest before:top-0 before:left-0 before:w-1 before:bg-[#2e3542] before:absolute before:h-full before:content-['  ']">
                        The winged horse is a strong symbol of balance,
                        combining the grounded power of the earth with the
                        freedom of the sky. The horse itself represents
                        strength, stability, and endurance, while its wings show
                        the ability to rise above and explore new heights.
                        Together, they remind us that true power comes from
                        balancing what holds us steady and what helps us soar.
                        This symbol speaks to both staying grounded and reaching
                        beyond our limits, showing that we can unite strength
                        and freedom to go further than we might imagine. The
                        combination of the horse, symbolizing the earth, and its
                        wings, representing the sky, creates a strong symbol of
                        balance. It shows the harmony between physical strength
                        and the freedom to fly, uniting horizons!
                    </blockquote>
                </div>
            </section>
            <section className="relative">
                <div className="container mx-auto relative px-4 pt-0 pb-28">
                    <div className="w-full container mx-auto flex lg:justify-center pb-28">
                        <h1 className="w-3/4 font-Libre text-6xl lg:text-8xl uppercase lg:text-center">
                            Story Behind Each Horse.
                        </h1>
                    </div>
                    <div ref={obsidianRef} className="w-full overflow-x-hidden">
                        <div className="w-full flex flex-col gap-8 p-8">
                            <h1
                                className="text-5xl md:text-7xl md:text-right lg:text-9xl text-center uppercase font-Libre"
                                style={{
                                    transform: isInView
                                        ? "none"
                                        : "translateX(-200px)",
                                    opacity: isInView ? 1 : 0,
                                    transition:
                                        "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)",
                                }}
                            >
                                Obsidian
                            </h1>
                            <div className="flex gap-14 flex-col xl:flex-row md:items-start">
                                <img
                                    src="/assets/images/image00002.jpeg"
                                    alt="Black Winged Horse Named Obsidian"
                                    className="md:w-3/4 lg:w-2/4 lg:self-center"
                                    style={{
                                        transform: isInView
                                            ? "none"
                                            : "translateX(-200px)",
                                        opacity: isInView ? 1 : 0,
                                        transition:
                                            "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)",
                                    }}
                                />
                                <p
                                    className="text-xl lg:text-2xl xl:text-2xl leading-loose text-justify mt-32"
                                    style={{
                                        transform: isInView
                                            ? "none"
                                            : "translateX(200px)",
                                        opacity: isInView ? 1 : 0,
                                        transition:
                                            "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)",
                                    }}
                                >
                                    Black represents mystery and the unknown,
                                    hinting at secrets and deep thoughts.<br/>The
                                    wings show power, suggesting strength and
                                    respect. Transformation is a big part of
                                    this horse&apos;s story it&apos;s about
                                    change and growing through challenges.<br/>Even
                                    though it&apos;s dark in color, the black
                                    winged horse is consistent and reliable,
                                    showing determination. It also symbolizes
                                    protection, like a guardian watching over
                                    others.<br/>It serves as a reminder of our
                                    potential for growth and achievement in
                                    life.<br/>Together, these qualities make the
                                    black winged horse a strong symbol of
                                    mystery, strength, transformation,
                                    reliability, protection, independence, and
                                    potential.<br/>It encourages us to think about
                                    the unknown, our inner strength, and how we
                                    can grow and achieve our goals in life.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
